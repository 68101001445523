import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Alvearie/alvearie.github.io/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const ImageCard = makeShortcode("ImageCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p />
    <h2>{`Clinical Ingestion Videos`}</h2>
    <p>{`The following videos introduce and guide you through the details of the Alvearie Clinical Ingestion pattern.`}</p>
    <Row className="image-card-group" mdxType="Row">
      <Column colMd={4} colLg={4} mdxType="Column">
        <ImageCard aspectRatio="4:3" iconColor="dark" href="https://mediacenter.ibm.com/media/t/1_bus1xkhe" mdxType="ImageCard">
          <p><span parentName="p" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "790px"
              }
            }}>{`
      `}<span parentName="span" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "56.25%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAMBAgQF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB5zc7UoQD/8QAGRAAAgMBAAAAAAAAAAAAAAAAAhEAARAx/9oACAEBAAEFAqNQzKo3nc//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAcEAACAQUBAAAAAAAAAAAAAAAAEQIBAxASIXH/2gAIAQEABj8CWka+nbcBpDO4/8QAHRAAAgEEAwAAAAAAAAAAAAAAABEBITFRcUFh0f/aAAgBAQABPyFyH+WEjy36Syg3EC7PZbVM0yWP/9oADAMBAAIAAwAAABC4z//EABYRAAMAAAAAAAAAAAAAAAAAAAEQEf/aAAgBAwEBPxA1f//EABcRAAMBAAAAAAAAAAAAAAAAAAEQEUH/2gAIAQIBAT8QE1f/xAAaEAEBAAMBAQAAAAAAAAAAAAABEQAhMUGh/9oACAEBAAE/EABQeSfiYfHCFNTBSRB3w15cSCEH0RkZnx15DQ4AZ//Z')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="span" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Welcome",
                "title": "Welcome",
                "src": "/static/7d1e1233c54378edc5e185d31cc26526/a8f04/welcome.jpg",
                "srcSet": ["/static/7d1e1233c54378edc5e185d31cc26526/69549/welcome.jpg 288w", "/static/7d1e1233c54378edc5e185d31cc26526/473e3/welcome.jpg 576w", "/static/7d1e1233c54378edc5e185d31cc26526/a8f04/welcome.jpg 790w"],
                "sizes": "(max-width: 790px) 100vw, 790px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy"
              }}></img>{`
    `}</span>{`
Welcome to Alvearie & patterns`}</p>
        </ImageCard>
      </Column>
      <Column colMd={4} colLg={4} mdxType="Column">
        <ImageCard aspectRatio="4:3" iconColor="dark" href="https://mediacenter.ibm.com/media/t/1_qqw3a3nq" mdxType="ImageCard">
          <p><span parentName="p" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "790px"
              }
            }}>{`
      `}<span parentName="span" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "56.25%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAQCAwb/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAABm5Q6mbAX/8QAHBAAAgICAwAAAAAAAAAAAAAAAQIDBAAREhMz/9oACAEBAAEFAq8CyRGupxxp6hPTyOSen//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABwQAAEDBQAAAAAAAAAAAAAAAAABEZECECFhcf/aAAgBAQAGPwJ1aDLQKm71dP/EABwQAAMAAgMBAAAAAAAAAAAAAAABESExQVFx8P/aAAgBAQABPyFRVVe6Iiolr6yTcMsCUJ4rF3mXoP/aAAwDAQACAAMAAAAQvA//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAcEAEAAgMAAwAAAAAAAAAAAAABABEhQVExYZH/2gAIAQEAAT8QvHRKiw9snvJBIQRwAgFGGoeqBqo7GJe7ojUPlR+z/9k=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="span" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Deploy",
                "title": "Deploy",
                "src": "/static/fe2d3de0544942a84e3cded9bafeb298/a8f04/deploy.jpg",
                "srcSet": ["/static/fe2d3de0544942a84e3cded9bafeb298/69549/deploy.jpg 288w", "/static/fe2d3de0544942a84e3cded9bafeb298/473e3/deploy.jpg 576w", "/static/fe2d3de0544942a84e3cded9bafeb298/a8f04/deploy.jpg 790w"],
                "sizes": "(max-width: 790px) 100vw, 790px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy"
              }}></img>{`
    `}</span>{`
Deploying clinical ingestion`}</p>
        </ImageCard>
      </Column>
      <Column colMd={4} colLg={4} mdxType="Column">
        <ImageCard aspectRatio="4:3" iconColor="dark" href="https://mediacenter.ibm.com/media/t/1_zwi54kr8" mdxType="ImageCard">
          <p><span parentName="p" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "790px"
              }
            }}>{`
      `}<span parentName="span" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "56.25%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAwAG/8QAFAEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAABV1RM/UP/xAAcEAACAgIDAAAAAAAAAAAAAAABAwIEABESEzP/2gAIAQEAAQUCggTQau8sQ621fBkiMsHk7//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAECAQE/Aaf/xAAbEAACAgMBAAAAAAAAAAAAAAAAAQISESFhkf/aAAgBAQAGPwKLUVbptrwqREPJ/8QAGhABAAIDAQAAAAAAAAAAAAAAAQARITGR4f/aAAgBAQABPyG+8bYwe73CYVgNFRc5gjESrZ//2gAMAwEAAgADAAAAEBTP/8QAFREBAQAAAAAAAAAAAAAAAAAAAQD/2gAIAQMBAT8QQi//xAAXEQEBAQEAAAAAAAAAAAAAAAABACEx/9oACAECAQE/EBGT2//EABwQAQEAAgMBAQAAAAAAAAAAAAERACExQZFh8P/aAAgBAQABPxCWmjxkre8sCoa3eeMUJCrBv5vGJv6uXUlXoc47wWTQGf/Z')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="span" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Deploy",
                "title": "Deploy",
                "src": "/static/2a99a7925ac249d411b18602c8e5e7f8/a8f04/ingestingData.jpg",
                "srcSet": ["/static/2a99a7925ac249d411b18602c8e5e7f8/69549/ingestingData.jpg 288w", "/static/2a99a7925ac249d411b18602c8e5e7f8/473e3/ingestingData.jpg 576w", "/static/2a99a7925ac249d411b18602c8e5e7f8/a8f04/ingestingData.jpg 790w"],
                "sizes": "(max-width: 790px) 100vw, 790px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy"
              }}></img>{`
    `}</span>{`
Ingesting data through Nifi`}</p>
        </ImageCard>
      </Column>
      <Column colMd={4} colLg={4} mdxType="Column">
        <ImageCard aspectRatio="4:3" iconColor="dark" href="https://mediacenter.ibm.com/media/t/1_wq6pavjs" mdxType="ImageCard">
          <p><span parentName="p" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "790px"
              }
            }}>{`
      `}<span parentName="span" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "56.25%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAEDBf/EABQBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAActEgsIX/8QAGRAAAgMBAAAAAAAAAAAAAAAAARAAERIx/9oACAEBAAEFArmyihz/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAWEAEBAQAAAAAAAAAAAAAAAABBACD/2gAIAQEABj8CIz//xAAaEAEAAwEBAQAAAAAAAAAAAAABABEhMRCB/9oACAEBAAE/IbmV9EVNccyDZfrhP//aAAwDAQACAAMAAAAQSM//xAAVEQEBAAAAAAAAAAAAAAAAAAAQMf/aAAgBAwEBPxCn/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAHBABAAIBBQAAAAAAAAAAAAAAAQARMRAhUWFx/9oACAEBAAE/EFNEPVhIYbA4kY1VvBUGXqW6b//Z')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="span" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Deploy",
                "title": "Deploy",
                "src": "/static/7bc1de0959b0cf11c246750ce6a3d931/a8f04/explainingOrchestration.jpg",
                "srcSet": ["/static/7bc1de0959b0cf11c246750ce6a3d931/69549/explainingOrchestration.jpg 288w", "/static/7bc1de0959b0cf11c246750ce6a3d931/473e3/explainingOrchestration.jpg 576w", "/static/7bc1de0959b0cf11c246750ce6a3d931/a8f04/explainingOrchestration.jpg 790w"],
                "sizes": "(max-width: 790px) 100vw, 790px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy"
              }}></img>{`
    `}</span>{`
Explaining orchestration`}</p>
        </ImageCard>
      </Column>
      <Column colMd={4} colLg={4} mdxType="Column">
        <ImageCard aspectRatio="4:3" iconColor="dark" href="https://mediacenter.ibm.com/media/t/1_4mdpsf6c" mdxType="ImageCard">
          <p><span parentName="p" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "790px"
              }
            }}>{`
      `}<span parentName="span" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "56.25%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAEDBf/EABQBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAActKYWEL/8QAGRAAAgMBAAAAAAAAAAAAAAAAARAAERIx/9oACAEBAAEFArmyihz/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAWEAEBAQAAAAAAAAAAAAAAAABBACD/2gAIAQEABj8CIz//xAAbEAABBQEBAAAAAAAAAAAAAAABABEhMUEQgf/aAAgBAQABPyEtz0ESxioQLh+6l//aAAwDAQACAAMAAAAQLA//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAbEAEAAgIDAAAAAAAAAAAAAAABETEAECFRYf/aAAgBAQABPxBDAnsmMiUC1MYlEvRxpel//9k=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="span" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Deploy",
                "title": "Deploy",
                "src": "/static/760de70b7e3c3393ad16804327780ce7/a8f04/exploringNifi.jpg",
                "srcSet": ["/static/760de70b7e3c3393ad16804327780ce7/69549/exploringNifi.jpg 288w", "/static/760de70b7e3c3393ad16804327780ce7/473e3/exploringNifi.jpg 576w", "/static/760de70b7e3c3393ad16804327780ce7/a8f04/exploringNifi.jpg 790w"],
                "sizes": "(max-width: 790px) 100vw, 790px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy"
              }}></img>{`
    `}</span>{`
Exploring Nifi canvas`}</p>
        </ImageCard>
      </Column>
    </Row>
    <p />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      